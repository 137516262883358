<template>
  <div class="d-flex align-items-center justify-content-between header-section ">
    <div class="logo mr-3">
      <b-link :to="{ name: 'app-root' }">
        <img src="@/assets/images/exxtra/logo.png" />
      </b-link>
    </div>

    <div v-if="showlinks" class="links-container">
      <div class="d-flex justify-content-around align-items-center mr-4">
        <!-- primary -->

        <div>
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <a v-smooth-scroll class="links">
                Our Services
                <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.62915 1L6.50012 8L12.3711 1" stroke="#144F6A" stroke-linecap="round" />
                </svg>
              </a>
            </template>
            <div class="p-1 rounded-lg">
              <b-dropdown-item class="font-weight-bold">
                <b-link :to="{ name: 'nss-exxtra'}">
                  Nss Exxtra
                </b-link>
              </b-dropdown-item>
              <b-dropdown-item class="font-weight-bold">
                <b-link :to="{ name: 'exxtra-cash'}">
                  Exxtra Cash
                </b-link>
              </b-dropdown-item>
              <b-dropdown-item class="font-weight-bold">
                <b-link :to="{ name: 'exxtra-checkout'}">
                  Exxtra Checkout
                </b-link>
              </b-dropdown-item>

            </div>
          </b-dropdown>
        </div>


        <a v-if="displayLoanCalculator" id="loan-calculator-link" v-smooth-scroll href="#loan-calculator" class="links mx-1"
           @click="movetoElement('loan-calculator-link')"
        >
          Loan Calculator
        </a>
        <a id="how-we-work-link" v-smooth-scroll href="#how-we-work" class="links mx-1"
           @click="movetoElement('how-we-work-link')"
        >
          How we work
        </a>
        <a id="stories-link" class="links mx-1" @click="$router.push({ name: 'stories' })">
          Stories
        </a>
        <a id="faq-link" v-smooth-scroll href="#faq" class="links mx-1" @click="movetoElement('faq-link')">
          FAQs
        </a>
      </div>
    </div>

    <div class="menu-buttons d-flex">
      <div class="login-button mr-2">
        <b-link :to="{ name: 'auth-login' }">
          Login</b-link>
      </div>

      <div class="signup-button">
        <b-link :to="{ name: 'auth-register' }">
          Sign Up
        </b-link>
      </div>
    </div>

    <div class="mobile-menu-button">
      <button @click="showMobileMenu">
        <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.29688 6.64062H20.7031M4.29688 19.1406H20.7031H4.29688ZM4.29688 12.8906H20.7031H4.29688Z" stroke="#144F6A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { BCol, BDropdown, BDropdownItem, BImg, BLink, BRow, } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BLink,
    BImg,
  },
  props: {
    showlinks: {
      type: Boolean,
      default: true
    },
    displayLoanCalculator: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showMobileMenu() {
      const menu = document.getElementById('mobile-menu');
      menu.classList.toggle('hidden');
      document.body.classList.toggle('no-scroll');
    },
    movetoElement(id) {
      // console.log(this.$router.currentRoute);
      if (this.$router.currentRoute.name !== 'app-root') this.$router.push({ name: 'app-root' });
      document.getElementById(id)
          .click();
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
